import axios from '@axios';

const baseUrl = '/youtube/videos/access';

export default {
  createAccess(videoId) {
    return axios.post(`${baseUrl}`, {
      video_id: videoId,
    });
  },
  deleteAccess(videoId) {
    return axios.delete(`${baseUrl}/${videoId}`);
  },
};
