<style lang="scss">

</style>
<!--eslint-disable-->
<template>
  <div class="kiosk-channels-page" v-if="!loading">
    <b-card :title="`Manage channel videos - ${channel.name}`" class="mb-0">
      <b-card-text>
        This table displays all of the channels videos. Here you can control if the videos are available or not.
      </b-card-text>
      <div class="mb-2">
        <b-row class="d-flex justify-content-end">

          <b-col cols="12" md="6">
            <b-input-group>
              <div>
                <label>
                  In {{accessSetting | capEachWord}}
                </label>
                <b-select
                  v-model="filters.in_access_list"
                  @change="getMoreVideos(1)"
                  :options="inAccessListOptions"
                />
              </div>
            </b-input-group>
          </b-col>

          <!-- Search -->
          <b-col cols="12" md="6">
            <div class="mt-2">
              <b-input-group>
                <b-form-input
                  @keydown.enter="getMoreVideos(1)"
                  v-model="filters.search"
                  class="d-inline-block"
                  placeholder="Search by video name..."
                />
                <b-input-group-append>
                  <b-button @click="getMoreVideos(1)">Search</b-button>
                </b-input-group-append>
              </b-input-group>
            </div>
          </b-col>

        </b-row>
      </div>

      <!-- Table -->
      <section>
        <b-table
          class="data-table"
          hover
          :items="videos"
          :fields="headers"
          :current-page="currentPage"
          :per-page="0"
        >

          <template #cell(thumbnail)="data">
            <div>
              <b-avatar
                size="40"
                variant="light-primary"
                :src="data.item.thumbnail"
                class="bade"
              />
            </div>
          </template>

          <template #cell(title)="data">
              {{ data.item.title }}
          </template>

          <template #cell(video_id)="data">
            <a :href="data.item.video_id | formatYoutubeVideoUrl" class="font-weight-bold d-block text-nowrap" target="_blank">
              {{ data.item.video_id }}
            </a>
          </template>

          <template #cell(published_at)="data">
            {{ data.item.published_at | formatDateTime }}
          </template>

          <template #cell(actions)="data">
            <b-form-checkbox
              v-if="$can('Update', 'PurplePlay')"
              @change="(val) => handleAccess(data.item.id, val)"
              :checked="accessSetting === 'BLACKLIST' ? !data.item.in_access_list : data.item.in_access_list"
              name="check-button"
              :disabled="settingAccess"
              switch
              :key="data.item.id"
            >
            </b-form-checkbox>
            <span v-else>
              {{ accessSetting === 'BLACKLIST' ? (!data.item.in_access_list ? 'Available' : 'Unavailable') : (data.item.in_access_list ? 'Available' : 'Unavailable') }}
            </span>
          </template>

        </b-table>
        <b-row>
          <b-col md="6" class="my-1" v-if="total_count > filters.page_size">
            <b-pagination @change="getMoreVideos" :total-rows="total_count" :per-page="filters.page_size" v-model="currentPage" class="my-0"/>
          </b-col>
          <b-col class="ml-2 mt-1" v-if="total_count === 0">
            <p>No videos found.</p>
          </b-col>
        </b-row>
      </section>
    </b-card>
  </div>
  <section v-else>
    <b-spinner variant="primary" label="Loading channel details"></b-spinner>
  </section>
</template>
<!--eslint-enable-->

<script>
import helperService from '@/services/HelperService';
import { BAvatar } from 'bootstrap-vue';
import videoService from '../../../services/YoutubeVideoService';
import youtubeChannelService from '@/services/YoutubeChannelService';
import profileService from '@/services/ProfileService';
import YoutubeVideoAccessService from '@/services/YoutubeVideoAccessService';

export default {
  name: 'PurplePlayChannelDetails',
  components: {
    BAvatar,
  },
  data() {
    return {
      loading: true,
      settingAccess: false,
      inAccessListOptions: [
        {value: '', text: 'All'},
        {value: 'true', text: 'Yes'},
        {value: 'false', text: 'No'},
      ],
      accessSetting: 'BLACKLIST',
      channel: {},
      headers: [
        {
          key: 'thumbnail',
          label: 'Thumbnail',
        },
        {
          key: 'title',
          label: 'Title',
        },
        {
          key: 'video_id',
          label: 'Video ID',
        },
        {
          key: 'published_at',
          label: 'Published',
        },
        {
          key: 'actions',
          label: 'Available',
          class: 'text-right',
        },
      ],
      videos: [],
      filters: {
        page: 0,
        page_size: 25,
        search: '',
        in_access_list: '',
      },
      currentPage: 1,
      total_count: 0,
    };
  },
  mounted() {
    this.getDetails()
  },
  methods: {
    async getDetails() {
      try {
        // Get enterprise
        const {data: profileData} = await profileService.getProfile()
        this.accessSetting = profileData.enterprise_purple_play_video_access
        // Get channel
        const {data} = await youtubeChannelService.getChannel(this.$route.params.id)
        this.channel = data
        this.loading = false
        await this.getVideos()
      } catch(err) {
        helperService.showNotfyErr(this.$toast, err, 'Could not fetch channel, please refresh and try again')
      }
    },
    refreshVideos() {
      this.getMoreVideos(1);
    },
    getMoreVideos(val) {
      this.filters.page = val - 1; // api index starts at 0, bootstrap starts at 1 lol
      this.getVideos();
    },
    async getVideos() {
      try {
        const { data } = await videoService.getVideos(this.channel.channel_id, this.filters)
        this.videos = data.data;
        this.filters.page = data.meta.page;
        this.filters.page_size = data.meta.page_size;
        this.total_count = data.meta.total_count;
      } catch (err) {
        helperService.showNotfyErr(this.$toast, err, 'Could not fetch videos, please refresh and try again')
      }
    },
    handleAccess(id, available) {
      /*
        Blacklist: Available = no access list record
        Whitelist: Available = access list record
       */
      if(this.accessSetting === 'BLACKLIST') {
        if(available) {
          this.deleteVideoAccess(id)
        } else {
          this.createVideoAccess(id)
        }
      } else {
        if(available) {
          this.createVideoAccess(id)
        } else {
          this.deleteVideoAccess(id)
        }
      }
    },
    async createVideoAccess(id) {
      this.settingAccess = true
      try {
        await YoutubeVideoAccessService.createAccess(id)
        if(this.filters.in_access_list !== '') {
          await this.getVideos()
        }
      } catch(err) {
        helperService.showNotfyErr(this.$toast, err, 'Could not create access record for the video')
      } finally {
        this.settingAccess = false
      }
    },
    async deleteVideoAccess(id) {
      this.settingAccess = true
      try {
        await YoutubeVideoAccessService.deleteAccess(id)
        if(this.filters.in_access_list !== '') {
          await this.getVideos()
        }
      } catch(err) {
        helperService.showNotfyErr(this.$toast, err, 'Could not delete access record for the video')
      } finally {
        this.settingAccess = false
      }
    },
    clearFilters() {
      this.filters = {
        page: 0,
        page_size: 25,
        search: '',
        in_access_list: '',
      };
      this.refreshVideos()
    },
  },
};
</script>
