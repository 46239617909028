import axios from '@axios';
import HelperService from '@/services/HelperService'

const baseUrl = '/youtube';

export default {
  getVideos(channelId, filters) {
    const queryString = HelperService.buildSearchQueryString(filters);
    return axios.get(`${baseUrl}/channels/${channelId}/videos${queryString}`);
  },
  getVideo(id) {
    return axios.get(`${baseUrl}/videos/${id}`);
  },
};
